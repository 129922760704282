footer {
  background: url('../../images//footer-bg.png');
  width: 100%;
  background-size: 100% 100%;
  padding: 5rem 3rem;
  color: #969696;
  font-family: $tenor;
  .btn {
    background: url('../../images/btnbg.png') center no-repeat;
    background-size: 100%;
    width: 13rem;
    color: #FFFFFF !important;
  }
  .back {
    position: absolute;
    left: -18%;
    top: -12%;
  }
  h2 {
    color: $white;
    font-family: $tenor;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .small {
    font-size: 0.875rem;
  }
  .socials img {
    margin-right: 0.8rem;
  }
  .logo {
    width: 11rem;
    margin-bottom: 3rem;
  }
  .form-control,.form-control:focus {
    background-color: #0A0A0A29;
    margin-bottom: 1.6rem;
    color: #FFFFFF;

  }
  @include breakpoint-down(small) {
    .col-md-7 {
      margin-top: 3rem;
    }
  }
}