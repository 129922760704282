.details {
  background: $bodyColor;
  font-family: $tenor;
  @include breakpoint-down(small) {
    width: 100%;
  }

  .hero {
    width: 100%;
    @include breakpoint-down(small) {
      width: auto;
    }

    .container {
      margin: 5rem auto;

      .play {
        display: flex;
        justify-content: center;
        align-items: center;
        // color:linear-gradient(#80FF72,#7EE8FA);
        @include breakpoint-down(small) {
          margin-top: 4rem;
        }
        img {
          width: 80%;
          height: -10%;
          margin-left: 13rem;
          margin-bottom: 13rem;
          // margin: 0 0 20rem 13rem;

          @include breakpoint-down(small) {
            margin-right: 58%;
            width: 100%;
          }
        }
      }
      h1 {
        font-family: $tenor;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 1.5rem;
      }
      .bold {
        font-weight: 800;
        font-size: 1.75rem;
      }
      .about {
        @include breakpoint-down(small) {
          width: 100%;
        }
      }
      .button {
        margin-top: 2rem;
        left: 20%;
        position: relative;
        @include breakpoint-down(small) {
          display: flex;
          margin: 3rem auto -3rem 2rem;
          position: relative;
          cursor: pointer;
        }
      }
      .green {
        margin: -2.5rem 1rem 0 2rem;
        color: $green;

        font-weight: 400;
        font-family: $tenor;
        @include breakpoint-down(small) {
          margin: 1.5rem 1rem 0 -1rem;
        }
      }
    }
  }
  .packages {
    width: 90%;
    background: $white;
    margin: 0 0 0 auto;
    color: $fontColor;
    padding: 4rem 2rem;
    position: relative;
    @include breakpoint-down(small) {
      width: 100%;
      margin-top: -8rem;
      padding: 4rem 1rem;
    }
    .join {
      display: flex;
      @include breakpoint-down(small) {
        display: block;
      }
      h2 {
        @include breakpoint-down(small) {
          display: flex;
          justify-content: center;
        }
      }
    }
    .select-option {
      display: flex;
      @include breakpoint-down(small) {
        display: flex;
      }
    }

    .dropdowns {
      margin-left: 18rem;
      width: 15vw;
      padding: 0.5rem;

      // @include breakpoint-down(small) {
      //   min-width: 41vw;

      //   margin-right: 2rem;
      //   margin-bottom: 5rem;
      //   .css-z6ywgi-menu {
      //     rigth: auto;
      //     //  left: -2%;
      //     width: 37vw;
      //   }
      // }
      @include breakpoint-down(medium) {
        min-width: 10rem;

        left:-5rem;
        margin-bottom: 5rem;
        .css-z6ywgi-menu {
          rigth: auto;
          //  left: -2%;
          width: 37vw;
        }
      }
      button:focus {
        outline: 0;
      }
    }
    .flower {
      position: absolute;
      width: 12rem;
      bottom: -5%;
      left: -7rem;
      @include breakpoint-down(small) {
        left: -5rem;
      }
    }
    .col-md-4 {
      padding: 0 1.5rem 3rem 1rem;
      border-right: 1px solid;
      position: relative;
      @include breakpoint-down(small) {
        border-right: none;
        margin-bottom: 4rem;
      }
      .price {
        position: absolute;
        bottom: -5%;
        font-weight: 500;
        font-size: 1.25rem;
        align-items: center;
        .line {
          text-decoration: line-through;
          color: #939393;
          font-size: 1rem;
          margin-left: 1rem;
          margin-right: 4.5rem;
        }
        .discount {
          background: $green;
          padding: 0.5rem;
          color: $white;
        }
      }
    }
    .col-md-4:last-child {
      border-right: 0px solid;
    }
    .checklist {
      align-items: center;
      margin: 1.5rem 0 1rem 0;
      p {
        margin-bottom: 0;
      }
      img {
        margin-right: 1rem;
      }
    }
    h2 {
      font-family: $tenor;
      font-weight: 700;
      font-size: 3rem;
    }
    .package {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  .portfolio {
    margin: 5rem 0;
    width: 100%;
    .join {
      display: flex;
      @include breakpoint-down(small) {
        display: block;
      }
    }
    h1 {
      font-family: $tenor;
      font-weight: 700;
      margin-bottom: 2rem;
    }
    .select-option {
      display: flex;
      @include breakpoint-down(small) {
        display: flex;
      }
    }
    .dropdown-portfolio {
      margin-inline: 12rem;

      margin-left: 12.5rem;
      margin-top: 5rem;
      width: 15vw;
      // @include breakpoint-down(small) {
      //   left: -10rem;
      //   min-width: 9rem;
      //   margin-top: 6px;
      //   .css-13yp52c-menu {
      //     width: 38vw;
      //     left: auto;
      //     right: -2%;
      //   }
      // }
      @include breakpoint-down(medium) {
        left: 1rem;
        min-width: 10rem;
        margin-top: 6px;
        .css-13yp52c-menu {
          width: 38vw;
          left: auto;
          right: -2%;
        }
      }
    }

    .portfolio-images {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      .img {
        width: 15.8rem;
        margin: 0 1.5rem 2rem 0;
        @include breakpoint-down(small) {
          width: 90%;
          margin: 2rem auto;
        }
      }
    }
  }
  .reviews {
    width: 100%;
    h1 {
      font-weight: 700;
      font-size: 3rem;
      font-family: $tenor;
      @include breakpoint-down(small) {
        width: 100%;
        font-size: 1.5rem;
      }
    }
    .rating {
      color: #dea83f;
      margin: 0 0 0 0.5rem;
      @include breakpoint-down(small) {
        width: 100%;
      }
    }
    .dropdown {
      border: 1px solid;
      padding: 0.5rem;
      button:focus {
        outline: 0;
      }
      #dropdownMenuButton {
        background: transparent;
        border: none;
        color: white;
      }
    }
    hr {
      border: 0.3px solid;
    }
    .review-img {
      height: 3rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
    .name {
      font-weight: 700;
    }
    .descTime {
      font-weight: 400;
      font-size: 0.875rem;
      margin-left: 20rem;
      @include breakpoint-down(small) {
        display: grid;
        justify-content: flex-end;
        margin-left: 10rem;
      }
      @include breakpoint-down(medium) {
        display: grid;
        justify-content: flex-end;
        margin-left: 5rem;
      }
    }
    .desc {
      font-weight: 400;
      font-size: 0.875rem;
    }
    .star {
      height: 1.5rem;
    }
    .bg {
      background: url("../../images/portfolio/bg.png");
      padding: 3rem 0;
      background-size: 100% 100%;
      .white-bg {
        background: $white;
        padding: 1rem 1rem;
        position: relative;
        width: fit-content;
        margin: auto;
        .tape {
          position: absolute;
          top: -2.5rem;
          left: 29%;
        }
        .person {
          display: block;
          margin: auto;
          width: 100%;
        }
        p {
          color: $black;
          margin-top: 1rem;
          text-align: center;
          font-size: 1.25rem;
          font-weight: 700;
          font-family: $tenor;
        }
      }
      .contact {
        margin: 1.5rem auto;
        display: block;
      }
    }
  }
}
