.appointment {
  background-image: url("../../images/appointment/appointment-bg.png");
  font-family: $tenor;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  display: flex;
  @media screen and (max-width: 1150px) {
   height: 120vh;
   
  }
  .btn {
    background: url('../../images/btnbg.png') center no-repeat;
    width: 12rem;
    background-size: 100%;
    color: #FFFFFF;
  }
  .step-one {
    width: 100%;
    position: relative;
    @include breakpoint-down(small) {
      height: 50rem;
    }
    .glass {
      position: absolute;
      right: 0%;
      bottom: 1%;
      width: 10rem;
    }
    .pineapple {
      position: absolute;
      left: 0%;
      bottom: 10%;
      width: 10rem;
    }
    .container {
      display: block;
      text-align: center;
      max-width: 60rem;
      margin-top: 2rem;
      position: relative;
      h2{
        font-family: $tenor;
        font-weight: 700;
        font-size: 2rem;
        margin: 1.5rem 0;
      }
      .back {
        position: absolute;
        left: 1%;
      }
      select,input {
        width: 100%;
        height: 2.5rem;
        margin-bottom: 2.2rem;
        background:rgba($fontColor, 0.16);
        border: 1px solid #939393;
        color: $white;
        padding: 0 0.5rem;
        color-scheme: dark;
  
        
      }
      .d-flex {
        justify-content: space-between;
        select,input {
          width: 30%;
        }
      }
    }
  }
  .step-two {
    width: 100%;
    .container {
      position: relative;
      margin: 3rem auto;
      text-align: center;
      max-width: 60rem;
    h2{
      font-family: $tenor;
      font-weight: 700;
      font-size: 2rem;
      margin: 1.5rem 0;
    }
    .back {
      position: absolute;
      left: 1%;
    }
    .box-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .green {
        border: 1px solid $green !important;
        color: $green;
        position: relative;
        .check {
          width: 1.5rem;
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
      }
    .box {
      border: 1px solid #939393;
      width: 19%;
      padding: 2rem 0.1rem;
      margin: 0 1.5rem 2rem 0;
      @include breakpoint-down(small) {
        width: 43%;
        margin-right: 0.5rem;
      }
      
      p {
        margin:1rem 0 0 0;
      }
      img {
        width: 6rem;
      }
    }
  }
 }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 600px;
  position: relative;
  .dino {
    position: absolute;
    width: 4rem;
  }
  .para {
    position: absolute;
    top: 70%;
  }
}
.step-three {
  width: 100%;
  .container {
    position: relative;
    margin: 3rem auto;
    text-align: center;
    max-width: 70rem;
    .cards-div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .box {
        padding: 2rem 0.1rem;
        margin: 0 1.5rem 2rem 0;
        text-align: left;
        @include breakpoint-down(small) {
          margin-right: 0;
        }
        .person {
          width: 14rem;
          height: 20rem;
        }
        .bold {
          font-weight: 700;
          margin: 1rem 0;
        }
        .price-div {
          font-size: 0.875rem;
        }
        .review {
          font-size: 0.75rem;
          color: $yellow;
          font-weight: 700;
        }
        .btn {
          color: $green;
          margin: 1.5rem auto;
          border: 2px solid $green;
          width: 99%;
          height: 3.5rem;
        }
        .green {
          background-color: $green;
          color: $white;
        }
      }
    }
  h2{
    font-family: $tenor;
    font-weight: 700;
    font-size: 2rem;
    margin: 1.5rem 0;
  }
  .back {
    position: absolute;
    left: 1%;
  }
}
}
.step-five,.step-seven,.step-eight {
  width: 100%;
  .header {
    position: relative;
    margin: 3rem auto;
    text-align: center;
    max-width: 60rem;
    h2{
      font-family: $tenor;
      font-weight: 700;
      font-size: 2rem;
      margin: 1.5rem 0 8rem 0;
    }
    .back {
      position: absolute;
      left: 1%;
    }
  }
  .packages {
    width: 90%;
    background: $white;
    margin: 0 0 0 auto;
    color: $fontColor;
    padding: 4rem 2rem;
    position: relative;
    @include breakpoint-down(small) {
      width: 100%;
      padding: 6rem 1rem;
    }
   
    .flower {
      position: absolute;
      width: 12rem;
      bottom: -5%;
      left: -7rem;
      @include breakpoint-down(small) {
        left: -5rem;
      }
    }
    .potrait{
      border: 2px groove black;
      width: 10vw;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: -10rem auto 0 50rem;
      position: relative;
      padding: 10px;
      background: white;
       
      @include breakpoint-down(small) {
      
        margin: -10rem auto 0 10rem;

        width: 50%;
      }
      .monicas {
     width: 100%;
     height: 100%;
        
      }
      .vendorImage{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow-wrap: anywhere;
        
        @include breakpoint-down(small) {
          height: 100%;
          width: 100%;
          overflow-wrap: anywhere;
        }
      }
     }
    .monica {
      position: absolute;
      top: -6rem;
      right: 26%;
      width: 10rem;
      @include breakpoint-down(small) {
        top: -4rem;
        right: 2%;
        width: 8rem;
      }
    }
    .col-md-4 {
      padding: 0 1.5rem 9rem 1rem;
      border-right: 1px solid;
      position: relative;
      @include breakpoint-down(small) {
        border-right: none;
        margin-bottom: 6rem;
        padding-bottom: 8rem;
      }
      .btn {
        position: absolute;
        bottom: -4rem;
        color: $green;
        margin: 1.5rem auto;
        border: 2px solid $green;
        width: 90%;
        height: 3.5rem;
      }
      .green {
        background: $green;
        color: $white;
      }
      .price {
        position: absolute;
        bottom: 2rem;
        font-weight: 500;
        font-size: 1.25rem;
        align-items: center;

        .line {
          text-decoration: line-through;
          color: #939393;
          font-size: 1rem;
          margin-left: 1rem;
          margin-right: 4.5rem;
        }
        .discount {
          background: $green;
          padding:0.5rem;
          color: $white;
        }
      }
    }
    .col-md-4:last-child {
      border-right: 0px solid;
    }
    .checklist {
      align-items: center;
      margin: 1.5rem 0 1rem 0;
      p {
        margin-bottom: 0;
      }
      img {
        margin-right: 1rem;
      }
    }
    h2 {
      font-family: $tenor;
      font-weight: 700;
      font-size: 3rem;
    }
    .package {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
.step-five {
  .nxt {
    display: block;
    margin: 2rem auto;
    position: initial;
    background: url('../../images/btnbg.png') center no-repeat;
    width: 12rem;
    background-size: 100%;
    color: #FFFFFF;
  }
}
.step-three, .step-five {
  .btn {
    background: none;
  }
  .nxt {
    display: block;
    margin: 2rem auto;
    position: initial;
    background: url('../../images/btnbg.png') center no-repeat;
    width: 12rem;
    background-size: 100%;
    color: #FFFFFF;
  }
}
.step-five{
  
 }
.step-six {
  width: 100%;
  position: relative;
  .container {
    display: block;
    text-align: center;
    max-width: 60rem;
    margin-top: 2rem;
    position: relative;
    h2{
      font-family: $tenor;
      font-weight: 700;
      font-size: 2rem;
      margin: 1.5rem 0;
    }
    .back {
      position: absolute;
      left: 1%;
    }
    form {
      width: 80%;
      margin: auto;
    select,input {
      width: 100%;
      height: 2.5rem;
      margin-bottom: 2.2rem;
      background:rgba($fontColor, 0.16);
      border: 1px solid #939393;
      color: $white;
      padding: 0 0.5rem;
      color-scheme: dark;
    }
    input[type=checkbox] {
      accent-color: $green;
      width: 1.5rem;
  }
  }
  }
}
.step-seven {
  .packages {
    padding: 4rem 2rem 0 2rem;
   .potraitt{
      border: 2px groove black;
      width: 10vw;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: -10rem auto 0 50rem;
      position: relative;
      padding: 10px;
      background: white;
       
      @include breakpoint-down(small) {
      
        margin: -10rem auto 0 10rem;

        width: 50%;
      }
      .monicasss {
     width: 100%;
     height: 100%;
        
      }
      .vendorImage{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow-wrap: anywhere;
        
        @include breakpoint-down(small) {
          height: 100%;
          width: 100%;
          overflow-wrap: anywhere;
        }
      }
     }
  .col-md-4 {
    border: none;
  }
  h2{
    font-size: 2.5rem;
  }
  .package {
    margin:0 1rem 0 0;
  }
  .total {
    margin-top: 4rem;
    .heading {
      font-weight: 600;
    }
    .payment {
      display: block;
      margin: 4rem 0 0 auto;
    }
  }
}
}
.step-eight {
  .packages {
    .dbsbank {
      height: 1.5rem;
      margin-right: 1rem;
    }
    .bold {
      font-weight: 600;
      margin-right: 1rem;
    }
    .col-md-4 {
      border: none;
      h2{
        margin-bottom: 2.5rem;
      }
    }
    .total {
      .heading {
        font-weight: 600;
      }
    }
    .payment {
      display: block;
      margin: 4rem 0 0 auto;
    }
  }
}
.img-logo {

  margin: 0;
  width: 138px;
  height: 48px;
     position: absolute;
     top: 10%;
     left: 50%;
     transform: translate(-50%, -50%);
     @include breakpoint-down(small) {
      width: 50%;
      
    }
}

.thank-you{

  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;

  
  h2{
    font-family: $tenor;
    font-weight: 700;
    font-size: 2rem;
    margin: 1rem 0 0 4rem;
  }

}
 
}
select option {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}