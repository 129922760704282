.orders {
  font-family: $tenor;
  .container {
    margin: auto;
    h1 {
      font-family: $tenor;
      font-weight: 700;
      font-size: 4rem;
      margin: 1.5rem 0;
    }
    .table {
      background: #302E2E;
      border-radius: 8px;
      margin: 3rem 0;
      thead {
        background:  #41594F;
      }
      tbody,
      td,
      tfoot,
      th,
      thead {
        border-style: none;
      }
      .Pending {
        color: #E9840F;
      }
      .Cancelled{
        color: #E42828;
       

      }
      .Completed {
        color: $green;
      }
      .Progress {
        // color: #b07d62;
        color: #f7aef8;
      }
      .Refunded {
        color:  #5297FF;
      }
      tbody {
        tr {
        border-bottom: 1px solid #939393;
        line-height: 2.5rem;
      }
    }
    }
  }
}
.orders-details {
 
  .container {
    margin: 2rem auto;

    .order {
      background: #302E2E;
      border-radius: .5rem;
      margin: 2rem auto;
      padding: 1rem 2rem;
      font-family: $tenor;
      hr {
        border-color:$grey ;
      }
      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        margin: 1rem 0 1.5rem 0;
        font-family: $tenor;
      }
      .bold {
        font-size: 1.25rem;
        font-weight: 600;
        font-family: $tenor;
      }
      .text {
        font-weight: 600;
        margin: 0 1rem 0 0;
        font-family: $tenor;

      }
      .white-bg {
        // background: #ffffff;
        // padding: 1rem 1rem;
        // position: relative;
        // width: -moz-fit-content;
        // width: fit-content;
        // margin: 3rem 0;
        background: $white;
        padding: 1rem 1rem;
        position: relative;
        width: fit-content;
        margin: 3rem 0;
        .tape {
          position: absolute;
          top: -2.5rem;
          left: 29%;
        }
        .monica{
          width: 10rem;
        }
        .person {
          display: block;
          margin: auto;
        }
        p{
          color: $black;
          margin-top: 1rem;
          text-align: center;
          font-size: 1.25rem;
          font-weight: 700;
          font-family: $tenor;
        }
      }
      .col-md-6:nth-child(2) {
        padding-left: 3rem;
        font-family: $tenor;

        @include breakpoint-down(small) {
          padding-left: 0;
          margin-top: 3rem;
        }
        .checklist {
          align-items: center;
          margin: 1.5rem 0 1rem 0;
        font-family: $tenor;

          p {
            margin-bottom: 0;
          }
          img {
            margin-right: 1rem;
          }
        }
        .total {
          margin-top: 2rem;
        font-family: $tenor;

          .table {
            background: #302E2E;
            border-radius: 8px;
            margin: 0rem auto 0px -0.8rem;
            thead {
              background:  none;
              
            }
           
            tbody,
            td,
            tfoot,
            th,
            thead {

              border-style: none;
        font-family: $tenor;

            }  
       
            tbody {
              tr {
              border-bottom: 1px solid #939393;
              line-height: 2.5rem;
        font-family: $tenor;
              
            }
            .qty{
              padding-left: 6px;
        font-family: $tenor;

            }
            
            .gsts{
              display: flex;
              flex-direction: row;
              align-content: center;
              justify-content: space-between;
        font-family: $tenor;

            }

            .head{
              border-bottom: none !important;
        font-family: $tenor;

            }
           
          }
          }
          .heading {
            font-weight: 600;
        font-family: $tenor;

          }
      
          .payment {
            display: block;
            margin: 4rem 0 0 auto;
        font-family: $tenor;

          }
        }
        
      }
      .btn-danger {
        width: 100%;
        margin: 3rem 0;
        background: #E42828;
        font-family: $tenor;

      }
    }
  }
}