.vendor {
  hr {
    border-color: $white;
  }
  .logo {
    position: absolute;
    top: 3%;
  }
  .none {
    display: none;
  }
  .sidenav---sidenav-toggle---1KRjR {
    float: right;
  }
  .bigMenu {
    margin-left: 11rem;
  }
  .sidenav---sidenav---_2tBP {
    background: #302E2E;
  }
  .sidenav---expanded---1KdUL {
    min-width: 210px !important;
  }
  .sidenav---sidenav-nav---3tvij {
    margin-top: 2rem ;
  }
  .topnav{
    margin: 1rem 0 0 0rem;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    .input-group {
      width: 30%;
    .searchBox {
      background: transparent;
      border: 1px solid $white;
    }
    .search-img {
      background: transparent;
      position: absolute;
      right: 1px;
    }
  }
  .icons {
    img{
      margin-right: 1rem;
    }
      display: flex;
      align-items: center;
  }
  @include breakpoint-down(small) {
    padding: 0rem;
    margin-left: 3.5rem;
    .input-group {
      width: 50%;
    }
    .nav-link {
      padding: 0;
    }
    .icons {
      margin-left: 0.5rem;
    }
  }
}
.home {
  margin: 3rem;
  @include breakpoint-down(small) {
    margin: 1rem .5rem 3rem 3.5rem;
    .mobile-margin {
      margin-bottom: 2rem;
    }
  }
  .desc {
    font-size: 0.875rem;
    color: $grey;
  }
  .number {
    font-size: 1.5rem;
  }
  .projects {
    background: #302E2E;
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imagesLines {
      display: flex;
      align-items: baseline;
      img {
        margin-right: 0.8rem;
      }
    }
  }
  .charts {
    background: #302E2E;
    padding: 2rem;
    border-radius: 8px;
    margin: 3rem 2rem 0 0;
    @include breakpoint-down(small) {
      margin: 2rem 0;
    }
    select {
    background: transparent;
    color: $white;
    height: 3rem;
    width: 10rem;
    padding: 0 0.5rem;
    border-radius: 8px;
    }
  }
  .upcoming {
    background: #302E2E;
    padding: 2rem 1.5rem;
    border-radius: 8px;

    .round {
      height: 3rem;
      width: 3rem;
    border-radius: 3rem;
    }
    .calendar {
      height: 1rem;
      margin-right: 0.5rem;
    }
    .customer,.desc {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
    .date {
      font-size: 0.65rem;
      color: $green;
      margin-bottom: 0;
    }
    .grid {
      display: grid;
    }
  }
}
.profile,
.payment {
  margin: 3rem 0 3rem 4rem;
  .container {
    margin-bottom: 3rem;
    h1 {
    font-family: $tenor;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
  .profile-content, .form-submission {
    background: #302E2E;
    text-align: center;
    border-radius: 8px;
    padding: 1rem 0;
    .uploadBox {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $bodyColor;
      height: 14rem;
      width: 80%;
      margin: 2rem auto;
    }
    .my-profile {
      text-align: left;
      margin-left: 1rem;
      font-weight: 600;
    }
    input[type=checkbox]  {
      width: 2rem;
      height: 1rem;
      transform: scale(1.5);
      margin: 0 0 2rem 1rem;
    }
    input[type=checkbox] {
      accent-color: $green;
    }
    img {
      width: 4rem;
    }
    .profilePic {
      width: 5rem;
      border-radius: 50%;
      height: 5rem;
    }
    .bookmark {
      width: 1.5rem;
    }
    hr {
      border-color: $white;
    }
    .change,.remove {
      display: block;
      width: 85%;
      background-color: #2952F3;
      color: $white;
      margin: 1rem auto 1rem auto;
      border-radius: 0;
    }
    .remove {
      background: #69C4E4;
    }
    
  }
  .form-submission {
    text-align: left;
    height: 100%;
    position: relative;
    padding-bottom: 15rem;
    @include breakpoint-down(small) {
      margin-top: 2.5rem;
    }
    form {
      margin: 0 1rem;
      .form-control, footer .form-control:focus {
        background-color: #0A0A0A29;
        color: $white;
     }
      input {
        margin-bottom: 1.5rem;
      }
      .bottom {
        position: absolute;
        bottom: 3%;
        width: 90%;
      .buttons-div,
      .buttons-left {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(small) {
          display: block;
        }
        .pass {
        color: $green;
        margin: 0 0 0 0.8rem;
        text-decoration: underline;
      }
      .btn-success,.btn-cancel {
        color: $white;
        background: $green;
        border-radius: 0;
        width: 28%;
        padding: 0.6rem 0rem;
        @include breakpoint-down(small) {
          width: 40%;
          margin: 1rem 1rem 0 0;
        }
      }
      .btn-cancel {
        background: transparent;
        border: 0.5px solid;
      }
      img {
        width: 1.5rem;
      }
    }
    .buttons-left {
      display: flex;
      justify-content: end;
    }
  }
}
  }
}
}
.packages,
.portfolio {
  margin: 3rem 0 3rem 4rem;
  ul {
    padding-left: 0;
    li {
      height: 3rem;
      list-style-type: none;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      &.active {
        background: #302E2E;
        border-radius: .5rem;
      }
    }
  }
  .info {
    background: #302E2E;
    padding: 2rem 1rem 5rem 1rem;
    margin-left: 1rem;
    @include breakpoint-down(small) {
      .mobile-margin {
        margin-bottom: 2rem;
      }
    }
    
    .p-image {
      height: 14rem;
      margin-bottom: 1.5rem;
    }
    .uploadBox {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $bodyColor;
      height: 14rem;
      margin: 0 .5rem;
    }
    .packageA,
    .packageB,
    .packageC {
      background: $white;
      padding: 1.5rem 0.8rem;
      color: #302E2E;
      border-radius: .5rem;
      font-weight: 600;
      margin: 0 0.5rem 1rem 0.5rem;
    }
    .btn-div {
      margin: 0 .5rem;
    .btn-success,.btn-cancel {
      color: $white;
      background: $green;
      border-radius: 5px;
      width: 45%;
      padding: 0.4rem 0rem;
      @include breakpoint-down(small) {
        width: 40%;
        margin: 1rem 1rem 0 0;
      }
    }
    .btn-cancel {
      background: transparent;
      border: 0.5px solid;
    }
  }
}
}
}