.navbar {
  width: 100%;
  background: #212020;
  color: #ffffff;
  padding: 0;
  font-family: $tenor;

  .navbar-brand {
    margin-left: 6rem;
  }

  .nav-item {
    
    a {
      font-size: 14px;
 
    }
    div .menu .font {
      color: #000;
      letter-spacing: 1px;
      font-family: $tenor;
      font-weight: 300;
      cursor: pointer;
    
      &:hover {
        color: #18bb78;
      }
    }
  }
  // c8b6ff
  .left {
    margin: 0 auto;
    .nav-item {
     
      margin-left: 2rem;
      a {
        color: #ffffff;
        font-family: $tenor;
        font-weight: 400;
       
        &:hover {
          color: #18bb78;

          text-decoration: none;
          
        }
        @include breakpoint-down(small) {
      text-align: center;
          
        }
      }
    }
  }

  .right {
    align-items: baseline;
    .nav-item {
      margin-right: 2rem;
      .scroll{
        scroll-behavior: smooth;
      }
      .call {
        width: 20px;
        opacity: 0.4;
        cursor: pointer;
      }
    }
    .last-nav {
      margin-right: 0;
    }
    .btn {
      background: #18bb78;
      color: #ffffff;
      border-radius: 0;
      height: 6rem;
    }
    .menu {
      right: 0;
      left: auto;
    }

    .menu_1{
      margin-inline: .4rem;
        font-size: 1.5rem;
        display: flex;

        // justify-items: center;
        // align-items: center;
        // justify-content: center;
    }
  
    .icon {
      font-size: 22px;
      margin-inline: .4rem;

    }

  }
  @media (max-width: 767px) {
    .navbar-brand {
      margin-left: 0;
    }
    .navbar-toggler {
      background: #ffffff;
    }
    ul {
      text-align: center;
    }
    .nav-item {
      margin: 0 0 1rem 0 !important;
    }
    .hide {
      // display: none;
    }
    .right {
      align-items: initial;
      .btn {
        height: auto;
      }
    }
  }
}
