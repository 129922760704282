.blog {
    background: $bodyColor;
    font-family: $tenor;
    .detail {
      .btn {
        color: #18BB78;
        font-family: $tenor;
        font-weight: 600;
      }
    }
    .hero,.latest,.older,.detail {
      width: 100%;
    .container {
      margin: 5rem auto;
      @include breakpoint-down(small) {
        padding: 0rem 1rem;
      }
      .readTxt {
        color: $green;
      }
      .play {
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint-down(small) {
          margin-top: 4rem;
        }
      }
      .old {
          width: 10rem;
          margin-right: 1rem;
      }
      h1,h6,h2 {
        font-family: $tenor;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: 2rem;
      }
      h6 {
        font-size: 1.5rem;
        margin-top: 1.5rem;
      }
    }
  }  
  .latest,.older {
    .col-md-6 {
      padding: 0 1rem;

      .blog-img {
        width: 99%;
        height: 23rem;
        @include breakpoint-down(small) {
          height: 18rem;
        }
      }
    }
  }  
  @media (max-width:767px) {
   .detail {
    h1 {
      font-size: 2rem !important; 
    }
   }
  } 
}