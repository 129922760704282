.box{
    margin: 50px;
    margin-left: 100px;
    font-family: $tenor;
    .form-control{
        margin-bottom: 1rem;
        width: 30rem;
        @include breakpoint-down(small) {
            width: 100%;
          }
    }

    .rbox{
        display: flex;
        justify-content: space-between;

        width:100%;
        @include breakpoint-down(small) {
           margin-right: -2rem;
           display:grid;
           justify-content: center;
          }

          .iBox{
            @include breakpoint-down(small) {
               margin-top: 10px;
               }

          }
    }
}