// $monstert: 'Montserrat';
$cabin: 'Cabin Sketch', cursive;
// $gotu: "Gotu";
$tenor:'Tenor Sans', sans-serif;
$white: #ffffff;
$black: #000;
$bodyColor:#262323;
$fontColor: #0A0A0A;
$green:#18BB78;
$darkGreen:#00724C;
$yellow:#DEA83F;
$grey:#939393;
$skin: #e5e5e5;
$red: #E42828;



