// .password-field {
//     color: white;
//     background-color: black;
//   }
  ::-ms-reveal {
    display: none;
  }
 
    .eye_ic {
      position: absolute;
      opacity: 0.5
    }
    .eye_ic1{
      position: absolute;
      opacity: 0.5
    
    }

  