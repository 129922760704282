.portfolio {
    background-color: $bodyColor;
    font-family: $tenor;
    h1 {
      font-family: $tenor;
      font-size: 4rem;
      font-weight: 700;
      margin-top: 4rem;
    }
  
    .filter-div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

    
      .form-control  {
        width: 40%;
          border-color: #939393;
          background: none;
          // margin-left: 1.5rem;
        &:focus {
          box-shadow: none;
          border-color: #939393;
          color: $white;
        }
        @include breakpoint-down(small) {
          width: 100%;
      
        }
      
        
      }
      .css-b62m3t-container{
        width: 100%;
        margin-right: 13rem;
        @include breakpoint-down(small) {
          min-width:100%;
          margin-top: 10px;
          
        }
      }
     
      .css-b62m3t-container  {
          
          width: 28%;
          .true__control {
            border-color: #939393;
            background: none;
            &:focus {
              box-shadow: none;
              border-color: #939393 !important; 
              color: $white;
            }
          }
          .true__indicator-separator {
            display: none;
          }
          .true__menu {
            background: none;
          }
          .true__single-value {
            color: $white;
          }
      }
    }
 
    .cards-div {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;
      .box {
        width: 15.3rem;
        margin-bottom: 10rem !important;
        .person {
          width: 100%;
          height: 64%;
        }
        margin-bottom: 2rem;
        margin-left: 1.3rem;
        cursor: pointer;
      }
      .review-div {
        display: flex;
        justify-content: space-between;
       margin: 1rem 0;
      }
      .price-div {
        display: flex;
        justify-content: space-between;
      }
      p {
        margin-bottom: 0.3rem;
        margin-top: 1rem;
      }
      span {
        font-size: 0.9rem;
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
  .read {
    margin: 0;
  
    .craft {
      display: none;
    }
  }
  @media (max-width: 767px) {
    
    .read {
      .text-div, .img-div
      {
        width: 100%;
      }
      .text-div {
        padding: 1rem;
      }
    }
  
  }
  }