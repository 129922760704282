.home {
  background-color: $bodyColor;
  font-family: $tenor;

  .hero {
    height: 37.5rem;
    width: 100%;
    color: $white;
    @include breakpoint-down(small) {
      margin-bottom: -200px;

    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40rem;
      overflow: hidden;
      pointer-events: none;

      @include breakpoint-down(small) {
        width: 100%;
        height: 50vh;
        overflow: hidden;
        pointer-events: none;

      }

    }

    .container {
      margin: 5rem auto;

      @include breakpoint-down(small) {
        margin-top: -450px;
        margin-left: -60px;
        scale: 60%;
      }

      h1 {
        font-family: $tenor;
        font-size: 5rem;
        font-weight: 700;
        @include breakpoint-down(small) {
          margin-top: 28.125rem;
          font-size: 3rem;
          align-items: center;
        }
      }
      .button{
        margin-top: 2rem;
        left: 20%;
        position: relative;
        @include breakpoint-down(small) {
           
          display: flex;
          margin: 3rem auto -3rem 6rem;
          position: relative;
          cursor: pointer;
       
        }
        
      }
    }
  }
  .bg {
    background: url("../../images/bg.png");
    width: 95%;
    // height: auto;
    margin: 0 0 0 auto;
    @include breakpoint-down(small) {
      width: 100%;
      
    }

    .services {
      margin: 4rem 0;
      @include breakpoint-down(small) {
        margin: auto;
        width: 100%;
        // padding: 10px;
              }

.heading{
  @include breakpoint-down(small) {
    position: relative;
display: flex;
margin: 2rem auto 2rem -3rem;
            }
}
      h1 {
        font-family: $tenor;
        font-size: 5rem;
        font-weight: 700;
        margin-left: 2rem;
        
       
      }
      .container{
        @include breakpoint-down(small) {
          display: grid;
          position: relative;
          margin: -2rem auto 0 -3.6rem;
          min-width: 24rem;
        }
      }
      .gallery {
          position: relative;
          display: grid;
          grid: auto;
          margin: 2rem 0 0 2rem;
    
         
          .img {
            width: 100%;
            height: 100%;
          }
          @include breakpoint-down(small) {
          
            width: 100%;
            height: auto;
          }
          p {
            position: relative;
            font-size: 1.25rem;
            font-weight: 600;
        font-family: $tenor;
          }
        .gallery__item--1{
          position: relative;
          display: flex;
          justify-content: space-around;

          @include breakpoint-down(small) {
            display: grid;
            width: 100%;
            height: 100%;
           
              }
          .arrow{
            width: 100%;
            height: 30%;
            margin-right: -30px;
            @include breakpoint-down(small) {
             display: none;
             
             }
          }
           .img{
            width: 50vw;
            height: 50vh;
            object-fit: cover;
            @include breakpoint-down(small) {
             
              width: 100%;
              height: 100%;
            
            }
           }
 
        
        }

        .gallery__item--2{
          position: relative;
          display: flex;
          // gap: 4.5rem;
          justify-content: space-around;

          @include breakpoint-down(small) {
            display: grid;
            width: 100%;
            height: 100%;
            
          }
          .img{
            width: 50vw;
            height: 50vh;
            object-fit: cover;
            @include breakpoint-down(small) {
             width: 100%;
             height: 100%;
            
            }
           }
         
         
          .arrow{
            width: 100%;
            height: 30%;
            margin-right: -30px;
            @include breakpoint-down(small) {
              display: none;
              
              }
          }
        }

        .gallery__item--3{
          position: relative;
          display: flex;
         
          justify-content: space-around;

          @include breakpoint-down(small) {
            display: grid;
            width: 100%;
            height: 100%;
          
          }
          .img{
            width: 50vw;
            height: 50vh;
            object-fit: cover;
            @include breakpoint-down(small) {
            
              width: 100%;
              height: 100%;
            
            }
           }
          
           .arrow{
            width: 100%;
            height: 30%;
            margin-right: -30px;
            @include breakpoint-down(small) {
              display: none;
              
              }
          }
          // .img:hover{
     
          //   transform: scale(1.2);
          //   overflow: hidden;
          //   transform: all 0.3s linear;
          //   box-shadow: -6px -1px 32px 0px rgba(0, 0, 0, 0.75);
          //   @include breakpoint-down(small) {
             
          //     display: none;
            
          //   }
          // }
        }
        .gallery__item--4{
          position: relative;
          display: flex;
          justify-content: space-around;

          @include breakpoint-down(small) {
            display: grid;
            width: 100%;
            height: 100%;
          
          }
          .img{
            width: 50vw;
            height: 50vh;
            object-fit: cover;
            @include breakpoint-down(small) {
        
              width: 100%;
              height: 100%;
            
            }
           }
          //  .img:hover{
     
          //   transform: scale(1.2);
          //   overflow: hidden;
          //   transform: all 0.3s linear;
          //   box-shadow: -6px -1px 32px 0px rgba(0, 0, 0, 0.75);
          //   @include breakpoint-down(small) {
             
          //     display: none;
            
          //   }
          // }
           .arrow{
            width: 100%;
            height: 30%;
            margin-right: -30px;
            @include breakpoint-down(small) {
              display: none;
              
              }
          }
        }
        .gallery__item--5{
          position: relative;
          display: flex;
          justify-content: space-around;

          @include breakpoint-down(small) {
                  display: grid;
                  width: 100%;
                  height: 100%;
                
                }
          .img{
            width: 50vw;
            height: 50vh;
           
            object-fit: cover;
            
            @include breakpoint-down(small) {
            
              width: 100%;
              height: 100%;
            
            }
           }
          //  .img:hover{
     
          //   transform: scale(1.2);
          //   overflow: hidden;
          //   transform: all 0.3s linear;
          //   box-shadow: -6px -1px 32px 0px rgba(0, 0, 0, 0.75);
          //   @include breakpoint-down(small) {
             
          //     display: none;
            
          //   }
          // }
           .arrow{
            width: 100%;
            height: 30%;
            margin-right: -30px;
            @include breakpoint-down(small) {
              display: none;
              
              }
          }
        }
    
      }
     
    }


    
    .works {
      background: url("../../images/works-bg.png");
      background-size: 100% 100%;
      width: 95%;
      background-repeat: no-repeat;
      margin: 0 auto 0 0;
      padding-bottom: 2rem;
      @include breakpoint-down(small) {
        background-size: cover;
        width: 100%;
        margin-top: -4rem;
        padding: 2rem 0;
        img {
          width: 4rem;
          display: block;
          margin: 1rem auto;
        }
        .arrow {
          display: none;
        }
      }
      img {
        width: 5rem;
      }
      .container {
        margin: auto;
        h2 {
          text-align: center;
          font-family: $tenor;

          padding: 7rem 0 4rem 0;
        }
        h3 span {
          color: #18bb78;
          font-family: $tenor;
          margin-right: 0.6rem;
        }
      }
    }
    .testimonials {
      width: 95%;
      margin: 0 auto 0 0;
      .container {
        margin: auto;
        position: relative;
        .girl {
          position: absolute;
          top: -14rem;
          width: 47%;
          @include breakpoint-down(small) {
            top: -5rem;
            display: none;
          }
        }
        .testimonial-heading {
          position: absolute;
          left: 43%;
          top: 27%;
          @include breakpoint-down(small) {
       
            position: absolute;
            left: 13%;
            font-size: 3rem;
            top: 14%;
          
          
          }
        }
        .testimonial-cards {
          display: flex;
          padding-top: 16rem;
          margin-left: 40%;
          margin-bottom: 5rem;
          @media (max-width: 1200px) {
            padding-top: 15rem;
            margin-left: 34%;
            p {
              font-size: 0.875rem;
             
            }
          }
          @include breakpoint-down(small) {
            margin: -8rem auto 0 -6.5px;
            
            width: 20em;
            display: block;
          
          }

          .card {
            width: 46%;
            // height: 10rem;
            color: $black;
            padding: 2rem 1rem;
            margin-left: 2rem;
            @include breakpoint-down(small) {
              width: 99%;
              margin-bottom: 3rem;
            }
            .star {
              margin-right: 0.3rem;
            }
            .tape {
              position: absolute;
              top: -6%;
              left: 43%;
            }
          }
        }
        .testimonialpic{
          width: 3rem;
    height: 3rem;
    border-radius: 50%;
        }
      }
    }
  }
  .community {
    background-color: $white;
    width: 80%;
    margin: 0 0 0 auto;
    color: $black;
    @include breakpoint-down(small) {
      width: 99%;
    }
    .container {
      margin: 8rem auto;
      padding: 0 7rem;
      position: relative;
      @include breakpoint-down(small) {
        padding: 0 1rem;
      }
      .globe {
        position: absolute;
        right: 1%;
        top: -40%;
        @include breakpoint-down(small) {
          right: 1%;
          top: -38%;
        }
      }
      h2 {
        font-family: $tenor;

        font-weight: 700;
      }
   .owl-theme{
   
    background:linear-gradient(#3B7197, #4A8DB7, #74BDE0, #A1E1FA);
    .item{
      display: flex;
      align-content: center;
      justify-content: space-around;
      margin-top: 4rem;
      
    }
   }
      .slider{
        background-color: $white;
        height: 10rem;
        width: 100%;
        display: inline-block;
     
        @include breakpoint-down(small) {
          height: 10rem;
          border: 1px solid;
          width: 100%;
          display: inline-block;
        }
          .photos{
            width: 100%;
            height: 10rem;
          //  margin-left: 2rem;
            position: relative;
        
          }
      }
    
      img {
        width: 12rem;
      }
     
      .d-flex h2 {
        color: $green;
      }
    }
  }
  .read{
    @include breakpoint-down(small) {
    margin-top:-5.5rem;
            
    }
  }
}
@media (max-width: 767px) {
  .services {
    width: 100%;
    height: auto;

  }
}
