.profile {
  font-family: $tenor;
  .container {
    margin-bottom: 3rem;
    h1 {
    font-family: $tenor;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 1.5rem;
   
  }
  .profile-content, .form-submission {
    margin-top:2.5rem;
    background: #302E2E;
    text-align: center;
    border-radius: 8px;
    padding: 1rem 0;
    .my-profile {
      
      text-align: left;
      margin-left: 1rem;
      font-weight: 600;
    }
    img {
      width: 4rem;
    }
    .profile {
      width: 5rem;
      border-radius: 50%;
      height: 5rem;
    }
    .bookmark {
      width: 1.5rem;
    }
    hr {
      border-color: $white;
    }
    .change,.remove {
      display: block;
      width: 85%;
      background-color: #2952F3;
      color: $white;
      margin: 1rem auto 1rem auto;
      border-radius: 0;
    }
    .remove {
      background: #69C4E4;
    }
    
  }
  .form-submission {
    text-align: left;
    height: 100%;
    position: relative;
    padding-bottom: 5rem;
    @include breakpoint-down(small) {
      margin-top: 2.5rem;
    }
    form {
      margin: 0 1rem;
      .form-control, footer .form-control:focus {
        background-color: #0A0A0A29;
        color: $white;
     }
    //  select {
    //   height: 2.4rem;
    //   width: 10%;
    //   margin-right: 0.5rem;
    //   background-color: #0A0A0A29;
    //   color: $white;
    // }
    .PhoneInput {
      width: 100%;
    }
    .PhoneInputInput {
      background-color: #0A0A0A29;
      color: $white;
      height: 2.4rem;
      width: 100%;
      border: 1px solid;
    }
    .PhoneInputCountryIconImg {
      width: 100%;
    }
    .PhoneInputCountry {
      margin: 0 1rem 1rem 0;
    }
      input {
        margin-bottom: 1.5rem;
      }
      .bottom {
        position: absolute;
        bottom: 3%;
        width: 90%;
      .buttons-div {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(small) {
          display: block;
        }
        .pass {
        color: $green;
        margin: 0 0 0 0.8rem;
        text-decoration: underline;
      }
      .btn-success,.btn-cancel {
        color: $white;
        background: $green;
        border-radius: 0;
        width: 28%;
        padding: 0.6rem 0rem;
        @include breakpoint-down(small) {
          width: 40%;
          margin: 1rem 1rem 0 0;
        }
      }
      .btn-cancel {
        background: transparent;
        border: 0.5px solid;
      }
      img {
        width: 1.5rem;
      }
    }
  }
}
  }

}
}