.login {
    background-color: $darkGreen;
    display: flex;
    min-height: 100vh;
    font-family: $tenor;
    position: relative;
    @include breakpoint-down(small) {
      display: block;
    }
    .btn {
      background: url('../../images/btnbg.png') no-repeat center;
      background-size: 100%;
      width: 12rem;
      display: block;
      margin: 0 auto;
      color: #FFFFFF;
    }
    .craft {
      position: absolute;
      right: 1%;
      width: 15rem;
    }
    .navbar {
      background: transparent;
    }

    .left {
      background: url('../../images/login/login-bg.png');
      width: 50%;
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 8rem 0;
      @include breakpoint-down(small) {
        width: 100%;
      }
      .navbar-brand {
        img {
          position: absolute;
          top: 6%;
        }
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $black;
      width: 50%;
      padding: 9rem 2rem 8rem 2rem;
      @include breakpoint-down(small) {
        width: 100%;
      }
      .right {
        width: 80%;
    
        @media (min-width: 1600px) {
          width: 60%;
        }
        @include breakpoint-down(small) {
          width: 90%;
        }
      
        input::-ms-reveal,
        input::-ms-clear {
          display: none;
        }
      
        h1 {
          font-family: $tenor;
          font-weight: 700;
          margin-bottom: 2rem;
        }
        h6 {
            font-family: $tenor;
            font-weight: 700;
            margin-bottom: 2rem;
          }
        .form-control {
          background: #0A0A0A29;
          margin-bottom: 1.5rem;
          color: #FFFFFF;
        }
        .green {
          color: $green;
          font-weight: 600;
        }
        input[type=checkbox] ,input[type=radio] {
          width: 2rem;
          height: 1rem;
          transform: scale(1.5);
          margin-bottom: 2rem;
      }
      input[name="gender"],input[type=checkbox] {
        accent-color: $green;
      }
      }
    }
  }

  