.service-page {
  background-color: $bodyColor;
  h1 {
    font-family: $tenor;
    font-size: 5rem;
    font-weight: 700;
    margin-top: 4rem;
  }
  .sub-service {
    .container {
      max-width: 1800px !important;
    }
    p {
      width: 40%;
      font-family: $tenor;
    }
    .view-div {
      display: flex;
      margin-top: 6rem;
      align-items: center;
      justify-content: space-between;
      ul {
        width: 13%;
        list-style: none;
        padding-inline-start: 0;
        li {
          margin-bottom: 3rem;
          cursor: pointer;
          padding-bottom: 0.4rem;
        }
        .active {
          border-bottom: 1px solid $white;
        }
      }
      .images-div {
        background: url("../../images/services/ist.png") no-repeat center;
        background-size: 100% 100%;
        display: grid;
        // grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(8, 4rem);
        grid-gap: 15px;
        width: 82%;
        height: auto;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        } 
       
        .content {

          display: grid;
          grid-template-columns: auto auto;
          margin: 4px;
          width: 100%;
          height: auto;
           
          @include breakpoint-down(small) {
            width: 100%;
            grid-template-columns: auto;
            
           }
          .iframe {
            padding: 10px;
            width: 98.5%;
            height: 19rem;
            border-radius: 1rem;
            
          }
          
        }

        .flower {
          position: absolute;
          width: 8rem;
          height: 14rem;
         
          left: -4rem;
        }
        .gallery__item--1 {
          width: 100%;
          height: 38.5rem;
          padding: auto;
         
        }
      }
    }
  }

 
  @media (max-width: 767px) {
    .promotion {
      .pro {
        width: 100%;
        h1 {
          font-size: 4.5rem;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
      .detail-div {
        width: 100% !important;
        .cards-div {
          display: block !important;
        }
      }
    }
    .read {
      margin-top: 600px !important; 
     
      .text-div,
      .img-div {
        width: 100%;
      }
      .text-div {
        padding: 1rem;
       

      }
    }
    .sub-service {
      .container {
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
        flex-wrap: wrap;
      }
      .images-div {
        width: 100% !important;
          
      }
   
      p {
        text-align: center;
        width: 100%;
      }
      .view-div {
        display: block;
      }
    }
  }
}
.read {
  background-color: $darkGreen;
  text-align: center;
  
  .craft {
    display: none;
  }
}
