html {
    font-size: 100%;
    box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    line-height: 1.4;
    color: $white;
    background-color: $bodyColor;
  }
.btn {
    &:focus {
    box-shadow: none;
    outline:  0 none;
}}

.read {
  margin: auto;
  .container {
    margin-top: 6rem;
    position: relative;
  .craft {
    position: absolute;
    right: -5%;
    top: -10rem;
    @media (max-width: 1100px) {
      right: -2%;
    }
  }
  .img-div {
    width: 60%;
    float: left;
    position: relative;
    // .dog {
    //   position: absolute;
    //   left: 32%;
    //   top: 25%;
    //   height: 18rem;
    //   width: 15rem;
    //   @include breakpoint-down(small) {
    //     left: 29%;
    //     top: 20%;
    //     height: 59%;
    //     width: 43%;
    //   }
    // }
    // @include breakpoint-down(small) {
    //   width: 100%;
    // }
.owner{
  width: 50%;
  height: 50%;
}
       .dog {
        position: absolute;
        left: 28%;
        top: 5%;
        height: auto;
        width: 19vw;
      @include breakpoint-down(small) {
        left: 29%;
        top: 6%;
        height: 87%;
        width: 43%;
      }
    }
    @include breakpoint-down(small) {
      width: 100%;
    }
  }
  .text-div {
    width: 35%;
    padding: 8rem 3rem;
    float: right;
    @media (max-width: 1200px) {
      width: 40%;
      padding: 3rem 3rem;
    }
    @include breakpoint-down(small) {
      width: 100%;
    }
  }
  }
  }
  .photography {
    width: 90%;
    margin: 3rem 0 3rem auto;
    @include breakpoint-down(small) {
      width: 100%;
    }
    h2{ 
      font-family: $cabin;
      font-weight: 700;
    }
    img {
      width: 90%;
      margin-left: 15rem;
    }
    .react-multiple-carousel__arrow--left {
      display: none;
    }
    .react-multiple-carousel__arrow--right {
      background: $green;
    }
    .react-multiple-carousel__arrow--right::before {
      content: "\2192";
    }
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-responsive-modal-modal {
  background: $green !important;
  color: black;
  width: 700px;
  text-align: center !important;
  h5 {
    width: 80%;
    margin:  2rem auto;
    text-align: center;
  }
  .btn {
    border: 1px solid black;
    margin-left: 1rem;
    background-color: #ffff;
    color: $green;

    &:hover {
      // background: black !important;
    }
  }
  .vendor-btn {
    color: #ffff !important;
    background: black !important;
  }
}
.makeStyles-container-1 {
  background: none !important;
}