.about {
  background: $bodyColor;
  font-family: $tenor;

  .hero,
  .works {
    width: 100%;

    .container {
      margin: 5rem auto;

      .play {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint-down(small) {
          margin-top: 4rem;
        }

        .owner {
          width: 480px;
          height: 550px;
          margin-bottom: 1rem;
        }

        .owner_1 {
          position: absolute;
          z-index: 1;
          white-space: nowrap;
          overflow: hidden;
        
        }
      }

      .video {

        pointer-events: none;

        @include breakpoint-down(small) {
          width: 100%;

        }
      }

      .padd {
        // padding: 3rem;

        .video-two {

          pointer-events: none;

          @include breakpoint-down(small) {
            width: 100%;


          }
        }
      }

      h1 {
        font-family: $tenor;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 1.5rem;
      }

      h2 {
        font-family: $tenor;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }

      .bold {
        font-weight: 600;
        font-size: 0.875rem;
      }

      .green {
        margin: 0 1rem 0 2rem;
        color: $green;
        font-weight: 400;
        font-family: $tenor;
      }

      .arrow {
        justify-content: center;
        margin-top: 5rem;
      }
    }
  }

  .photography {
    width: 90%;
    margin: 3rem 0 3rem auto;

    h2 {
      font-family: $tenor;
      font-weight: 700;
    }

    img {
      width: 90%;
      margin-left: 15rem;
    }

    .react-multiple-carousel__arrow--left {
      display: none;
    }

    .react-multiple-carousel__arrow--right {
      background: $green;
    }

    .react-multiple-carousel__arrow--right::before {
      content: "\2192";
    }
  }

}