.login {
  background-color: $darkGreen;
  display: flex;
  min-height: 100vh;
  position: relative;
  font-family: $tenor;
  @include breakpoint-down(small) {
    display: block;
  }
  .btn {
    background: url('../../images/btnbg.png') no-repeat center;
    background-size: 100%;
    width: 12rem;
    display: block;
    margin: 0 auto;
    color: #FFFFFF;
  }
  .craft {
    position: absolute;
    right: 1%;
    width: 15rem;
  }
  .navbar {
    background: transparent;
  }
  .forget{
    position: absolute;
left: -0.62%;

bottom: 67.28%;
width: 100%;
font-family: $tenor;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: right;
text-decoration-line: underline;

color: #FFFFFF;
  }
  .left {
    background: url('../../images/login/login-bg.png');
    width: 50%;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 8rem 0;
    @include breakpoint-down(small) {
      width: 100%;
    }
    .navbar-brand {
      img {
        position: absolute;
        top: 6%;
      }
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    width: 50%;
    padding: 9rem 2rem 8rem 2rem;
    @include breakpoint-down(small) {
      width: 100%;
    }
    .right {
      width: 80%;
  
      @media (min-width: 1600px) {
        width: 60%;
      }
      @include breakpoint-down(small) {
        width: 90%;
      }
    
      input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
      .eye {
        position: absolute;
        bottom: 0.2rem;
        right: 1%;
        width: 2rem;
      }
      h1 {
        font-family: $tenor;
        font-weight: 700;
        margin-bottom: 2rem;
      }
      .form-control {
        background: #0A0A0A29;
        margin-bottom: 1.5rem;
        color: #FFFFFF;
      }
      .green {
        color: $green;
        font-weight: 600;
      }
      input[type=checkbox] ,input[type=radio] {
        width: 2rem;
        height: 1rem;
        transform: scale(1.5);
        margin-bottom: 2rem;
    }
    input[name="gender"],input[type=checkbox] {
      accent-color: $green;
    }
    }
  }
}